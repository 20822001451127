
import Vue from 'vue';
import { Routes } from '@/router/routes';

import ClientInfoDialog from '@/components/Clients/ClientInfoDialog.vue';

export default Vue.extend({
  name: 'Client',

  components: { ClientInfoDialog },

  computed: {
    clientId(): string {
      return this.$store.getters['client/activeClient']?.id || null;
    },
    routeTab(): string {
      return this.$route.fullPath.split('/').pop();
    },
  },

  methods: {
    handleNav({
      name,
      replace = false,
      keepProps = false,
    }: {
      name: string;
      replace?: boolean;
      keepProps?: boolean;
    }): void {
      if (this.$route.name === name) return;

      const routeObj = { name, ...(keepProps && { params: { ...this.$route.params } }) };

      if (replace) {
        this.$router.replace(routeObj);
      } else {
        this.$router.push(routeObj);
      }
    },
    closeDialog() {
      this.$router.push({ name: Routes.Clients });
    },
  },
});
